import React from 'react';
import { Header, BackToTop, Footer, SEO } from '@components';
import { Layout } from '@layouts';

/**
 * The 404 page
 */
const NotFoundPage = () => {
	return (
		<Layout>
			<SEO noindex slug='404' title='SaniShield | 404' description='Page not found.' />
			<Header />

			<section className='error-404 bg-img3'>
				<div className='container'>
					<div className='ttm-big-icon ttm-textcolor-skincolor'>
						<i className='fa fa-thumbs-o-down' />
					</div>
					<header className='page-header'>
						<h1 className='page-title'>404 ERROR</h1>
					</header>
					<div className='page-content'>
						<p>This page may have been moved or deleted. Be sure to check your spelling.</p>
					</div>
					<a
						className='ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark mb-15'
						href='/'
					>
						Back To Home
					</a>
				</div>
			</section>

			<Footer />

			<BackToTop />
		</Layout>
	);
};

export default NotFoundPage;
